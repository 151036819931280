<template>
  <div class="navbar">
    <div class="companytitle" title="点击切换应用">
      <NxDrodown v-if="userInfo.userInTenantAppList&&userInfo.userInTenantAppList.length>1">
        <div> {{ currentApp ?currentApp.appName: userInfo.systemSettingList['systemName'] }}</div>
        <div slot="dropdown">
          <el-dropdown-item v-for="item in userInfo.userInTenantAppList" :key="item.id" :title="item.appName" :class="item.id === currentApp.id?'selectitem':''" class="overflowHidden" @click.native="handleChooseApp(item)">{{ item.appName }}</el-dropdown-item>
        </div>
      </NxDrodown>
      <div v-else class="title" style="color:#0096ff;cursor:default;"> {{ (userInfo.systemSettingList&&userInfo.systemSettingList['systemName']) ? userInfo.systemSettingList['systemName']:currentApp.appName }}</div>
    </div>
    <div class="right-nav">
      <div class="headersearch">
        <el-input v-model="valuesearch" placeholder="请输入内容" class="input-with-select">
          <el-select slot="prepend" v-model="searchSelected" placeholder="全部">
            <el-option label="功能" value="1" />
            <el-option label="订单" value="2" />
            <el-option label="客户" value="3" />
          </el-select>
          <i slot="suffix" class="el-input__icon el-icon-search" @click="search()" />

          <!-- <el-button slot="append" icon="el-icon-search"></el-button> -->
        </el-input>
      </div>
      <div v-if="siteList&&siteList.length>1" class="right-menu-item hover-effect divided">
        <el-dropdown class="website">
          <div class="site-title">
            <i class="cus-icon-web" style="font-size: 22px;color:#0096ff;margin-right: 3px;" /><span>{{ siteList.find(item=>item.selected).name }}</span><i class="el-icon-arrow-down" style="font-size:12px;margin-left: 3px;" />
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-for="item in siteList" :key="item.id" :title="item.name" style="font-size:12px;" :class="item.selected?'selectitem':''" @click.native="ChangeSite(item)">{{ item.name }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div class="right-menu-item hover-effect icon-font divided">
        <el-tooltip content="帮助中心" placement="bottom" effect="light" popper-class="el-popper-light">
          <div slot="content">
            不知如何操作？<br>
            查看帮助中心！
          </div>
          <svg-icon :icon-class="'help'" />
        </el-tooltip>
      </div>
      <div class="right-menu-item icon-font hover-effect">
        <el-tooltip content="客户服务" placement="bottom" effect="light" popper-class="el-popper-light">
          <svg-icon :icon-class="'service'" />
        </el-tooltip>
      </div>
      <screenfull id="screenfull" class="right-menu-item hover-effect" />
      <el-dropdown class="avatar-container right-menu-item hover-effect divided" placement="bottom-end" trigger="hover">
        <!-- <div class="avatar-wrapper">
          <span class="user-info">{{ user.name }}</span>
          <img :src="user.avatar" class="user-avatar">
          <i class="el-icon-arrow-down el-icon--right" />
        </div> -->
        <el-avatar style="margin-top: 7px;" :src="userInfo.avatar" :alt="userInfo.realName ?? userInfo.nickName" />
        <el-dropdown-menu slot="dropdown">
          <router-link to="/profile">
            <el-dropdown-item class="el-icon-s-tools" primary> 个人信息</el-dropdown-item>
          </router-link>
          <router-link to="/">
            <el-dropdown-item><i class="el-icon-delete color-green" />首页</el-dropdown-item>
          </router-link>
          <el-dropdown-item divided @click.native="logout">
            <span class="el-icon-circle-close"> 退出</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import Screenfull from '@/components/Screenfull'
import NxDrodown from '@/components/DropDown'
import { getAllSites } from '@/api/cms/site'

import { mapGetters } from 'vuex'
export default {
  name: 'Navbar',
  components: {
    Screenfull,
    NxDrodown
  },
  data() {
    return {
      valuesearch: '',
      searchSelected: '',
      currentApp: { }, // 当前用户所在租户中进入的APP应用
      siteList: [] // 站点列表
    }
  },
  computed: {
    ...mapGetters([
      'userInfo'
    ])
  },
  created() {
    this.initData()
  },
  methods: {
    initData() {
      this.currentApp = _.find(this.userInfo.userInTenantAppList, (app) => { return app.id === this.userInfo.appId })
      // 判断当前租户是否包含站点信息,如果购买了站点则加载站点信息
      if (this.userInfo.specInfo.length && this.userInfo.specInfo.filter(item => item.saleSpecId === 4)) {
        getAllSites().then(res => {
          this.$store.dispatch('user/setSiteInfo', res.data)
        }).then(() => {
          this.siteList = this.userInfo.userSiteList
        })
      }
    },
    search() {
      this.$alert('测试搜索')
    },
    async handleChooseApp(row) {
      if (row.appId !== this.currentApp.appId) {
        await this.$store.dispatch('user/changeApplication', row.id)
        // TODO 设置当前AppId
        location.href = row.path ? row.path : '/'
      }
    },
    async ChangeSite(row) {
      this.userInfo.userSiteList.find(item => item.selected).selected = false
      this.userInfo.userSiteList.find(item => item.id === row.id).selected = true
      await this.$store.dispatch('user/setSiteInfo', this.userInfo.userSiteList)
    },
    logout() {
      this.$confirm('您确定需要退出系统吗？', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$store.dispatch('user/logout')
      }).catch(() => {
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.navbar {
  height: 50px;
  overflow: hidden;
  position: relative;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  .companytitle {
    line-height: 46px;
    height: 100%;
    font-size: 18px;
    padding-left: 10px;
    color: #161616;
    float: left;
    cursor: pointer;
    transition: background 0.3s;
    -webkit-tap-highlight-color: transparent;

    &:hover {
      // background: rgba(0, 0, 0, 0.035);
      color: #0096ff;
    }
    :focus{
      outline: none;
    }
  }
  .right-nav {
    float: right;
    height: 100%;
    line-height: 50px;
    display: inline-flex;

    .headersearch {
      display: inline-block;
      padding: 0 8px;
      height: 100%;
      margin-right: 20px;
      ::v-deep .el-input-group__prepend {
        background-color: #fff;
        .el-select {
          .el-input {
            width: 68px;
            input {
              padding-left: 8px;
            }
          }
        }
      }
      .el-icon-search {
        cursor: pointer;
      }
    }
    .right-menu-item {
      display: inline-block;
      padding: 0 8px;
      height: 100%;
      font-size: 18px;
      color: #5a5e66;
      vertical-align: text-bottom;

      &.icon-font{
        font-size: 30px;
        padding: 0 3px;
      }
      .website{
        font-size: 13px;
        .site-title{
          display: flex;
          justify-self: center;
          align-items: center;
        }
      }
      &.hover-effect {
        cursor: pointer;
        transition: background 0.3s;

        &:hover {
          background: rgba(0, 0, 0, 0.025);
          color: rgb(92, 182, 255);
        }
      }
      &.divided{
        position: relative;
        padding-left: 10px !important;
        &::before{
          content: "";
          position: absolute;
          left: 1px;
          top: 20%;
          width: 0px;
          height: 50%;
          border-left:1px solid #f4f4f5;
        }
      }
    }

    // .avatar-container {
    //   margin-right: 5px;

    //   .avatar-wrapper {
    //     position: relative;

    //     .user-avatar {
    //       cursor: pointer;
    //       width: 35px;
    //       height: 35px;
    //       border-radius: 50%;
    //       float: left;
    //       margin-top: 7px;
    //     }
    //     .user-info {
    //       font-size: 14px;
    //     }
    //     .el-icon-arrow-down {
    //       cursor: pointer;
    //       font-size: 12px;
    //     }
    //   }
    // }
  }

}

</style>